import React, {useEffect,useRef,useState} from "react"
import { useUserSiteContext } from '../../hooks/useUserSite'
import { useSemiLiveDataContext } from '../../hooks/useSemiLiveDataContext'; 
import { useSitesContext } from "../../hooks/useSitesContext";
import { useUserContext } from "../../hooks/useUserContext";
import { useStaticDataContext } from "../../hooks/useStaticDataContext";
import DataChart from "../../components/site/DashboardChart";
import OverviewChart from "../../components/site/OverviewChart";
import LiveDataTab from "../../components/site/LiveDataTabs";
import DisplayChart from "./components/DisplayChart";
import { LIVE_CHART_DAYS, LIVE_CHART_RES } from "../../constants";
import Right from '../../svg_components/arrows/right.svg'
import { useNavContext } from "../../hooks/useNavContext";
import { ComponentLoading } from "../../components/subcomp/InlineLoading";
import LiveDisplay from "./components/LiveDisplay";
import logoDesktop from '../../images/Energyneering_Clean.png'


const _ = require('lodash');
const echarts = require('echarts')

const DashPage = React.memo(() => {
  
  const roorURL = window.location.origin
  const { dispatch, gatewayParam } = useSitesContext();
  const { liveChart,wsStatus,viewStatus,dispatchSemiLiveData } = useSemiLiveDataContext();
  const { userSite, dispatchUserSite } = useUserSiteContext();
  const { activeSiteData,userRole } = useUserContext();
  const { isLanscape,wsClass } = useStaticDataContext()
  const { dispatchNav } = useNavContext()

  const [chartDataPass,setChartDataPass] = useState(null)
  const [chartDataView,setChartDataView] = useState(null)
  const [chartSelect,setChartSelect] = useState(null)
  const [chartDaySelect,setChartDaySelect] = useState();
  const [displayMainChart,setDisplayMainChart] = useState(false)
  const [overviewBoard,setOverviewBoard] = useState(false)
  const elementRef = useRef(null);
  const overviewElementRef = useRef(null);
  const [ovChartData,setovChartData] = useState()
  const [showCharts,setShowCharts] = useState(true);
  
  const overviewSettings = {
    overlay:{
      style:{
        background:"white",
        opacity:1
      },
      settings:{

      }
    },
    basicInfoBar:{
      style:{
        background:'red',
        opacity:'1',
        width:'12%'
      }
    }
  }


  function thisInfo(item,gateway,device,param){
    return {prop:`${gatewayParam[item.site_id]?.dashSettings[gateway][device][param]?.page}.${gatewayParam[item.site_id]?.dashSettings[gateway][device][param]?.index}`,id:`${gateway}.${device}.${param}`}
  }


  useEffect(() => {
    dispatchNav({type:'NAV_INDEX',payload:'dash'})
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setDisplayMainChart(false);
        setOverviewBoard(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  useEffect(() => {
    if (elementRef.current && elementRef.current.id) {
      try {
        let e = document.getElementById(elementRef.current.id);
        if (displayMainChart) {
          e?.requestFullscreen();
        } else if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [displayMainChart]);

  useEffect(() => {
    if (overviewElementRef.current && overviewElementRef.current.id) {
      try {
        if (overviewBoard) {
          if (document.fullscreenElement) {
            document.exitFullscreen().then(() => {
              overviewElementRef.current.requestFullscreen();
            });
          } else {
            overviewElementRef.current.requestFullscreen();
          }
        } else if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [overviewBoard]);


  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setDisplayMainChart(false);
        setOverviewBoard(false);
      }
    };
  
    document.addEventListener("fullscreenchange", handleFullscreenChange);
  
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(()=>{
    if(viewStatus&&!wsStatus&&(wsStatus!==undefined)){
      dispatchSemiLiveData({type:'RESTART_WS',payload:true}) // restart ws
    }
  },[wsStatus,viewStatus])

  useEffect(()=>{
    if(chartDataPass&&chartDaySelect){
      let holdChartDataPass = _.cloneDeep(chartDataPass)
      
      const keyArray = Object.keys(chartDataPass)
      const keyCount = keyArray.length
      for(let i = 0;i<keyCount;i++){
        const indexMax = (LIVE_CHART_DAYS)*(1440/LIVE_CHART_RES)-(chartDaySelect[keyArray[i]])*(1440/LIVE_CHART_RES)
        const indexMin = indexMax-(1440/LIVE_CHART_RES)
        let _domain = []
        let _range = []
        for(let j =0;j<(1440/LIVE_CHART_RES);j++){
          _domain.push(chartDataPass[keyArray[i]]?.data.domain[j+indexMin])
          _range.push(chartDataPass[keyArray[i]]?.data.range[j+indexMin])
        }
        const hold5 = _domain
        const hold6 = _range
        holdChartDataPass[keyArray[i]].data.domain = _.cloneDeep(hold5)
        holdChartDataPass[keyArray[i]].data.range = _.cloneDeep(hold6)
        const _cleanNulls = interpolateNulls(holdChartDataPass[keyArray[i]]?.data.range,4);
        holdChartDataPass[keyArray[i]].data.rangeClean = _cleanNulls.arr
        holdChartDataPass[keyArray[i]].data.analysis = {average:null,sum:null,area:null,max:null,min:null}
        if(_cleanNulls)holdChartDataPass[keyArray[i]].data.analysis = basicDataAnalysis(_cleanNulls.arr,holdChartDataPass[keyArray[i]]?.step);
        
      }
      setChartDataView(holdChartDataPass)
    }
  },[chartDataPass,chartDaySelect])

  useEffect(()=>{
    if(gatewayParam&&chartDataPass&&activeSiteData){

      let holdChartDataPass = _.cloneDeep(chartDataPass)

      let _gatewayArray = gatewayParam[activeSiteData.siteID]?.dashSettings?.gateWays
      let _gatewayCount = _gatewayArray?.length

      for(let i = 0;i<_gatewayCount;i++){
        let _gatewayObj = gatewayParam[activeSiteData.siteID]?.dashSettings?.[_gatewayArray[i]]
        let _deviceArr = _gatewayObj?.listOfDevices
        let _deviceCount = _deviceArr?.length
        
        for(let j=0;j<_deviceCount;j++){
          let _deviceObj = _gatewayObj?.[_deviceArr[j]]
          let _paramArr = _deviceObj?.listOfParams
          let _paramCount = _paramArr?.length
          
          for(let k=0;k<_paramCount;k++){
            let _paramObj = _deviceObj?.[_paramArr[k]]
            
            if(_paramObj?.enableOverChart||_paramObj?.enableOverPiChart){
              let _ID = `${_gatewayArray[i]}.${_deviceArr[j]}.${_paramArr[k]}`

              let _domainArr = []
              let _rangeArr = []
              let _rangeArrClean = []
              let _rangeArrCleanFlag = []
              let _analysisArr = []

              for(let l = 0;l<LIVE_CHART_DAYS;l++){
                const indexMax = (LIVE_CHART_DAYS)*(1440/LIVE_CHART_RES)-(l)*(1440/LIVE_CHART_RES)
                const indexMin = indexMax-(1440/LIVE_CHART_RES)
                let _domain = []
                let _range = []
                
                
                for(let m =0;m<(1440/LIVE_CHART_RES);m++){
                  _domain?.push(chartDataPass[_ID]?.data?.domain[m+indexMin])
                  _range?.push(chartDataPass[_ID]?.data?.range[m+indexMin])
                }
                const _cleanNulls = interpolateNulls(_range,4);
                let  _analysis = {average:null,sum:null,area:null,max:null,min:null}
                _analysis = basicDataAnalysis(_cleanNulls.arr,holdChartDataPass[_ID]?.step);

                _domainArr.push(_domain)
                _rangeArr.push(_range)
                _rangeArrClean.push(_cleanNulls.arr)
                _rangeArrCleanFlag.push(_cleanNulls.pass)
                _analysisArr.push(_analysis)
              }

              const hold5 = _domainArr
              const hold6 = _rangeArr
              const hold7 = _rangeArrClean
              const hold8 = _rangeArrCleanFlag
              const hold9 = _analysisArr
              if(holdChartDataPass[_ID]?.data) {
                holdChartDataPass[_ID].data.domain = _.cloneDeep(hold5)
                holdChartDataPass[_ID].data.range = _.cloneDeep(hold6)
                holdChartDataPass[_ID].data.rangeClean = _.cloneDeep(hold7)
                holdChartDataPass[_ID].data.rangeCleanFlag = _.cloneDeep(hold8)
                holdChartDataPass[_ID].data.analysis = _.cloneDeep(hold9)
              }
            }
          }
        }
      }
      console.log("This is the chart data pass",holdChartDataPass)
      setovChartData(holdChartDataPass)
    }

  },[gatewayParam,chartDataPass,activeSiteData])

  useEffect(()=>{
    if((!isLanscape)&&(displayMainChart||overviewBoard)){
      setDisplayMainChart(false);
      setOverviewBoard(false);
    }
  },[isLanscape,displayMainChart,overviewBoard ]);

 
  useEffect(()=>{
    console.log("This is the live chart data issue",liveChart)
    if(liveChart){
      const chartDataCollection = processChartDataForEcharts(liveChart,activeSiteData,userRole) // this is not a constant since we are changing the name before passing it to the hook
      const allThechartValues = chartDataCollection.completeCart
      const gatewayCount = activeSiteData?.gateways?.length
      let chartDataControl = {}
      let holdChartDaySelect = {}
      let dataIsGood = true;
      for(let i = 0;i<gatewayCount;i++){
        const gatewayID = activeSiteData.gateways[i]?.gatewayID
        if(allThechartValues[gatewayID]?.data){ //setChartDataPass(allThechartValues[activeSiteData.gateways[i]?.gatewayID][0])
          const paramCount = allThechartValues[gatewayID]?.data.length
          for(let j = 0;j<paramCount;j++){
            const holdNameArray = allThechartValues[gatewayID]?.data[j]?.name.split('.') // liveChart
            allThechartValues[gatewayID].data[j].name = liveChart[activeSiteData.siteID][gatewayID].displayInfo[`${gatewayID}.${holdNameArray[0]}.${holdNameArray[1]}`]?.dashName // changing the name of the chart in the chart options
            const holdID = `${gatewayID}.${holdNameArray[0]}.${holdNameArray[1]}`
            chartDataControl[holdID] = {data:allThechartValues[gatewayID]?.data[j],addSet:allThechartValues[gatewayID]?.addSet[j],step:allThechartValues?.[gatewayID]?.step}
            holdChartDaySelect[holdID] = 0
            if(chartSelect === null&&!j)setChartSelect(`${gatewayID}.${holdNameArray[0]}.${holdNameArray[1]}`) // set the initial chart
          }
        }else {dataIsGood = false}
      }
      if(dataIsGood){
        setChartDataPass(chartDataControl)
        setChartDaySelect(holdChartDaySelect)
      }
    }
  },[liveChart])

  function selectChartFunction(selection){
    if(!isLanscape&&chartSelect){ // allow to deselect when it is not lanscape
      if(chartSelect===selection&&showCharts){
        setShowCharts(false)
        return
      }
    }
    setShowCharts(true)   
    setChartSelect(selection)
  }

  function dayChange(tabID,flag,requestID){
    try{
      if(!requestID){
        if(flag&&chartDaySelect?.[tabID]<(LIVE_CHART_DAYS-1)){
          let holdChartDaySelect = {...chartDaySelect}
          holdChartDaySelect[tabID] += 1
          setChartDaySelect(holdChartDaySelect)
        }else if((!flag)&&chartDaySelect[tabID]>0){
          let holdChartDaySelect = {...chartDaySelect}
          holdChartDaySelect[tabID] -= 1
          setChartDaySelect(holdChartDaySelect)
        }
      }else if(requestID===1){
        if(chartDaySelect){
          
          let _keyArr = Object.keys(chartDaySelect);
          let _max = 0;
          _keyArr.forEach((item,index)=>{if(chartDaySelect[item]>_max)_max = chartDaySelect[item];})
            const holdMax = _max;
          flag?_max++:_max--
          if(_max>LIVE_CHART_DAYS-1)_max = LIVE_CHART_DAYS-1;
          else if(_max<0)_max = 0;
          let _holdObj = {...chartDaySelect};
          _keyArr.forEach((item,index)=>{_holdObj[`${item}`]=_max;})

          if(holdMax!==_max)setChartDaySelect(_holdObj)
        }
        if(flag&&chartDaySelect){

        }
      }  
    }catch(e){
      console.error(e)
    } 
  }

  const DashDate = ()=>{

    return(
    <div id="dashDate">
      <div className="arrow-parent">
        <div className="arrow-container left">
          <div  className="arrow-left" onClick={()=>dayChange(null,true,1)}/>
        </div>   
        <h2 className="mid-arrow">{getDashDate(chartDataView,chartSelect)}</h2>
        <div className="arrow-container right">
          <div  className="arrow-right" onClick={()=>dayChange(null,false,1)}/>
        </div>
      </div>
    </div>
    )
  }
  
  
  return(
    <div className="site">
      <div className={isLanscape?'sitePage-flex':''} style={{width:'100vw'}}> 
          {!isLanscape&&<div className="dashboard-button adCon" style={{top:!isLanscape?'1.3rem':'0',zIndex:3}}>
            <DashDate/>
          </div>}   
        <div className={isLanscape?'liveData-container':'liveData_portrait-container'}>
          {activeSiteData&&userSite?.map((item, itemIndex) => ((item.site_id===activeSiteData.siteID)&&
          <ul key = {`sitesUl_${itemIndex}`}>
            {isLanscape&&<h2 style = {{alignContent:'center',width:'100%'}} key = {`siteH1_${itemIndex}`}>
              <button const onClick={()=>{isLanscape?setDisplayMainChart(true):alert("Only available in lanscape view")}} style = {{alignContent:'center',width:'90%',margin:'1rem'}} className="defaultButton">{item.siteName}</button>
              <button const onClick={()=>{isLanscape?setOverviewBoard(true):alert("Only available in lanscape view")}} style = {{alignContent:'center',width:'90%',margin:'1rem'}} className="defaultButton">Overview</button>       
            </h2>}
            <div key={`tableDiv_${itemIndex}`} className="liveData-holder">
              
              {gatewayParam&&gatewayParam[item.site_id]?.dashSettings?(gatewayParam[item.site_id]?.dashSettings.gateWays.map((gateway)=>( //
                gatewayParam[item.site_id]?.dashSettings[gateway]?.listOfDevices.map((device,deviceIndex)=>(
                  gatewayParam[item.site_id]?.dashSettings[gateway][device]?.listOfParams.map((param,paramIndex)=>(
                    <div key={`tabDiv_${itemIndex}${deviceIndex}${paramIndex}`} style={isLanscape?{}:{display:'flex',flexDirection: 'column',justifyContent:'center',width:'95vw'}} >
                      <li key = {`tabLi_${itemIndex}${deviceIndex}${paramIndex}`} style = {{border: (thisInfo(item,gateway,device,param).id===chartSelect)?'solid #00000033':'solid #9aaf9a'}} className="dashboard-button adCon" id = {thisInfo(item,gateway,device,param).id}>
                        <div className="innerSpan-div adCon" onClick={(e)=>{selectChartFunction(thisInfo(item,gateway,device,param).id)}}>
                          <LiveDataTab key ={`tab_${itemIndex}${deviceIndex}${paramIndex}`} argument = {{gateway,prop:thisInfo(item,gateway,device,param).prop,id:thisInfo(item,gateway,device,param).id,item:item}}/>
                        </div>  
                      </li>
                      <div className={`dash-chart-mobile ${(chartSelect===thisInfo(item,gateway,device,param).id)?'dash-chart-mobile-select':''}`}>
                        {((!isLanscape)&&(chartDataView?.[chartSelect]?.data?.domain?.length<150)&&(chartSelect===thisInfo(item,gateway,device,param).id)&&chartSelect)?<DataChart argument = {{data:chartDataView[chartSelect]?.data,clean:true,chartSelect,siteID:activeSiteData.siteID,addSet:chartDataView[chartSelect]?.addSet,userRole}} hide = {!showCharts}/>:<></>}
                        </div>
                      </div>
                  ))
                ))
              ))):(<ComponentLoading/>)}
            </div>
          </ul>
          ))}
        </div>
        
        {isLanscape?<div className="dashPage-chart" style={{ width: '75vw', height: '70vh' }}>
          <div className="dashboard-button adCon">
            <DashDate/>
          </div>  
          {(isLanscape&&chartDataPass&&chartSelect)?<div style={{height:'100%'}}><DataChart argument = {{data:chartDataView?.[chartSelect]?.data,clean:false,chartSelect,siteID:activeSiteData.siteID,addSet:chartDataView?.[chartSelect]?.addSet,userRole}}/></div>:<ComponentLoading/>}
        </div>:<></>}
      </div>
      {
        
        <div className={`form-popup-bg ${displayMainChart?'is-visible':''}`} ref={elementRef} id="liveViewChart" style={{background:chartDataView?.[chartSelect]?.addSet?.bckColour||''}}>
          {activeSiteData?.siteID&&<div id="chartHeader">
            <div id="chartLogo"><img src={logoDesktop} className="chartLogo" /></div>
            <div id="displayChartTitle"><h2>{`${liveChart?.[activeSiteData.siteID]?.[chartSelect?.split('.')[0]]?.displayInfo[chartSelect]?.dashName||''}`}</h2></div>
            <dev id="liveChartData"><LiveDisplay argument = {{data:chartDataView?.[chartSelect]?.data,clean:false,chartSelect,siteID:activeSiteData.siteID,addSet:chartDataView?.[chartSelect]?.addSet}}/></dev>
            <button onClick = {()=>{setDisplayMainChart(false)}} className="close-button">Esc</button>
          </div>}
          <div className="background">
            {activeSiteData&&userSite?.map((item, itemIndex) => ((item?.site_id===activeSiteData?.siteID)&&item?.media?.chartBackGround?<img src={`${roorURL}/api/sites/displayimage/${item?.media?.chartBackGround}`}
              className="bckgImage"
            />:<></>))}
          </div>
          <div className="content">
            {activeSiteData?.siteID&&chartDataView?.[chartSelect]?.data&&<div>{}</div>}
            {activeSiteData?.siteID&&chartDataView?.[chartSelect]?.data&&<DisplayChart argument = {{data:chartDataView?.[chartSelect]?.data,clean:false,chartSelect,siteID:activeSiteData.siteID,addSet:chartDataView?.[chartSelect]?.addSet,userRole}}/>}
          </div>
        </div>

      }
      {
        <div className={`form-popup-bg ${overviewBoard?'is-visible':''}`} ref={overviewElementRef} id="overviewBoard" style={overviewSettings.overlay.style}>
          <button onClick = {()=>{setOverviewBoard(false)}} className="close-button">Esc</button>
          {activeSiteData?.siteID&&<div id="chartHeader">
            <div ><img src={logoDesktop} className="chartLogo" /></div>
            <button onClick = {()=>{setDisplayMainChart(false)}} className="close-button">Esc</button>
          </div>}
          <div className="background">
            {activeSiteData&&userSite?.map((item, itemIndex) => ((item?.site_id===activeSiteData?.siteID)&&item?.media?.chartBackGround?<img src={`${roorURL}/api/sites/displayimage/${item?.media?.chartBackGround}`}
              className="bckgImage"
            />:<></>))}
          </div>
            {activeSiteData?.siteID&&ovChartData&&<OverviewChart argument = {{dataset:ovChartData,addSet:chartDataView?.[chartSelect]?.addSet}} chartDataView={chartDataView}/>}
          </div>
      }
    </div>)
})

export default DashPage

// helper function:


function processChartDataForEcharts(_chartPass,_activeSiteData,userRole){
  const siteID = _activeSiteData?.siteID
  const arrayOfAllSiteGateway = _activeSiteData?.gateways

  const gatewayCount = arrayOfAllSiteGateway?.length
  let returnObj = {}
  
  if(gatewayCount){for(let i = 0;i<gatewayCount;i++){
    if(_chartPass?.[siteID]){
      const gatewayID = arrayOfAllSiteGateway[i]?.gatewayID
      if((_chartPass[siteID]?.[gatewayID])&&(_chartPass?.[siteID]?.[gatewayID]!==undefined)){
        if(_chartPass[siteID][gatewayID]?.data?.time){
          
          let argument = _chartPass[siteID][gatewayID]
          
          const beginDate = argument.timeInfo.timeRequest.from[gatewayID]
          const offsetErrorForChart = argument.timeInfo.timeRequest.currentDate.offset
            const parameterCount = argument?.arrayList?.length
            let seriesArrays = []
            let addSetArray = []
            let thisObj = {}
            let addSettings = {}
            for(let i =0;i<parameterCount;i++){
              try{
              if(argument?.data&&argument?.arrayList){ // Here we are using the array list [[<device>,<parameter>]]
                const _holdID = `${gatewayID}.${argument.arrayList[i][0]}.${argument.arrayList[i][1]}`
                const dispColour=_chartPass?.[siteID]?.[gatewayID]?.displayInfo?.[_holdID]?.colour
                const bckColour=_chartPass?.[siteID]?.[gatewayID]?.displayInfo?.[_holdID]?.bckColour
                if(argument.data[argument.arrayList[i][0]]){
                                     
                    if(argument.data[argument.arrayList[i][0]][argument.arrayList[i][1]]?.average){
                      const amendedData = fillMissingData(argument.data[argument.arrayList[i][0]][argument.arrayList[i][1]]?.average,argument.data.time,argument.timeInfo.minInterval,beginDate,offsetErrorForChart)

                      addSettings = {colour:dispColour,bckColour}
                      thisObj = {
                          name: `${argument.arrayList[i][0]}.${argument.arrayList[i][1]}`,
                          // type: 'line',
                          
                          // tooltip:{
                          //   valueFormatter: value => value?value.toFixed(2):value
                          // },
                          // connectNulls:(userRole==='admin')?false:true,
                          domain: amendedData[0],                       
                          range: amendedData[1]
                      }
                      seriesArrays.push(thisObj)
                      addSetArray.push(addSettings)
                    } 
                  } 
              }
              }catch(error){
                console.error(error)
              }
            }
            if(gatewayID)returnObj[gatewayID] = {data:seriesArrays,addSet:addSetArray,step:argument.timeInfo.minInterval}
          }
        }
    }
  }}
  return {completeCart:returnObj}
}

function fillMissingData(dataArray, dateArray, step, beginDate,errorOffset) {
  const holdUnixTime = Math.round((new Date(beginDate).getTime())/1000)
  const fullTimeArrayCount = (1440*LIVE_CHART_DAYS)/(step>0?step:1)
  const secStep = step*60
  let arrayCounter = 0
  let newDataArray = []
  let newTimeArray = []

  for(let i = 0;i<fullTimeArrayCount;i++){
    newTimeArray[i] = (holdUnixTime+secStep*i+errorOffset)
    if(newTimeArray[i]===dateArray[arrayCounter]){
      newDataArray[i] = dataArray[arrayCounter]
      arrayCounter++
    }else{
      newDataArray[i] = null
    }
  }
  
  return [newTimeArray.map((e)=>e*1000),newDataArray]//[newTimeArray,newDataArray];
}

const getDashDate =(data,select)=>{
  const epoch = data?.[select]?.data?.domain?.[3];
  const _date = epoch?new Date(epoch):null;
  const returnString = _date?`${(_date?.toLocaleDateString('en-US', {  day: 'numeric',month: 'long' }))} (${_date?.toLocaleDateString('en-US', { weekday: 'long'})})`:'';
  return returnString;
}

function interpolateNulls(array, maxNulls) {
  let start = null; // Start index of null sequence
  let end = null;   // End index of null sequence
  let pass = true;
  let nonNullValues = true;
  let arr = _.cloneDeep(array);

  for (let i = 0; i < arr.length; i++) {
      if (arr[i] === null) {
          if (start === null) {
              start = i; // Mark the start of the null sequence
          }
          nonNullValues = false;
      } else {
          if (start !== null) {
              end = i; // Mark the end of the null sequence
              let nullCount = end - start; // Number of consecutive nulls

              if (nullCount <= maxNulls) {
                  // Interpolate values only if the sequence length is within the limit
                  let startValue = start > 0 ? arr[start - 1] : arr[end];
                  let step = (arr[end] - startValue) / (end - start + (start > 0 ? 1 : 0));
                  for (let j = start; j < end; j++) {
                      arr[j] = startValue + step * (j - start + (start > 0 ? 1 : 0));
                  }
              } else {
                  pass = false;
              }
              // Reset start and end for next sequence
              start = end = null;
          }
      }
  }

  if (nonNullValues && (!pass)) {
      pass = true;
  }

  return { arr, pass };
}


function basicDataAnalysis(arr,step){ // note that the step is the numner of minutes per data point. eg if it is 10 then the data is in a resolution of 10 minutes
  let arrayLength = arr?.length
  let max = arr[0];
  let min = arr[0];
  let sum = 0;
  let area = 0;
  let getInital = true

  let _index = arrayLength-1;
  while((arr[_index]===null||arr[_index]===NaN)&&_index){
    _index--;
  }

  for(let i = 0;i<_index;i++){
    
    if(!(arr[i]===null||arr[i]===NaN)){

      if(getInital){
        max = arr[i];
        min = arr[i];
        getInital = false;
      }

      sum = sum + arr[i];
      if(max<arr[i])max = arr[i];
      if(min>arr[i])min = arr[i];

      area = step*arr[i] + area // gives unit*minute
    }  
  }
  let average = sum/_index  
  
  return {average,sum,area,max,min}
}


