import React from 'react';
import lImg from '../../images/loading.svg';

function InlineLoading() {
    return <img style={{height:'3rem', width:'auto', margin:'-1rem'}} src={lImg} alt="Loading"/>;
}

function ComponentLoading() {
    return (
        <div className='loadImgDiv'>
            <img style={{ maxHeight:'8rem',height:'30%', width:'auto' }} src={lImg} alt="Loading"/>
        </div>
    );
}

export { InlineLoading, ComponentLoading };
