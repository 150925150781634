import React, { useState, useEffect} from 'react';
import { useUserContext } from '../../hooks/useUserContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useSitesContext } from '../../hooks/useSitesContext';
import { useNavContext } from '../../hooks/useNavContext';
import { ComponentLoading } from '../subcomp/InlineLoading';

const _ = require('lodash');
const _paramMetric = [['average','Average'],['sum','Sum'],['area','Area'],['max','Maximum'],['min','Minimum']] // from dashPage

const ConfigureDash = () => {
    const { dispatchNav,navIndex } = useNavContext()
    useEffect(()=>{
        dispatchNav({type:'NAV_INDEX',payload:'dashMan'})
    },[])
    
    const { dispatch, gatewayParam } = useSitesContext();
    const { user } = useAuthContext();
    const { activeSiteData } = useUserContext();
    const [error, setError] = useState(null);
    const [drivers,setDrivers] = useState(null);
    const [liStyles, setLiStyles] = useState({});
    //const [lastClickedLi, setLastClickedLi] = useState(null);
    const [listToFetchData,setListToFetchData] = useState(null)
    const [dashBoardNames,setDashBoardNames] = useState({})
    const [updateDashInfo,setUpdateDashInfo] = useState(false)
    const [deviceList,setDeviceList] = useState({})
    const [configSel,setConfigSel] = useState('conDash')
    const [ovCustomSection,setOvCustomSection] = useState([{}])
    const [listOfSelected,setListOfSelected]=useState([])

    const _operationList = [['none','None'],['mult',"Multiply"],['div','Divide'],['add','Add'],['sub','Subtract']]

    useEffect(()=>{console.log("This is the custom settings: ",ovCustomSection)},[ovCustomSection]) /// todo: remove

    useEffect(()=>{
      setOvCustomSection(_ovCustomSection)
    },[])
    const deviceNameRes =(deviceListName)=>{
      let holdDeviceList = {...deviceList}
      if(holdDeviceList?.[deviceListName]){
        holdDeviceList[deviceListName] = false
      }else {
        holdDeviceList[deviceListName] = true
      }
      setDeviceList(holdDeviceList)
    }

    useEffect(()=>{console.log("This is the stuff we are looking at: ",dashBoardNames)},[dashBoardNames])

    const handleDashSettingsFromServer = (_dashData)=>{
      if(_dashData&&_dashData?.gateWays){
        let objectFromServer_dash = {}
        let objectFromServer_select = {}
        let holdColorObject = {}
        try{
          const gatewayCount = _dashData?.gateWays.length
          for(let i = 0;i<gatewayCount;i++){
            if(_dashData.gateWays[i]){
              if(_dashData[_dashData.gateWays[i]]?.listOfDevices){
                const deviceCount = _dashData[_dashData.gateWays[i]].listOfDevices.length
                for(let j=0;j<deviceCount;j++){
                  const paramCount = _dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]].listOfParams.length
                  if(_dashData[_dashData.gateWays[i]]?.listOfDevices[j]){
                    for(let k = 0;k<paramCount;k++){
                      if(_dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]]?.listOfParams[k]){
                        const holdValue = _dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]][_dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]].listOfParams[k]]
                        const holdAbit = `${_dashData.gateWays[i]}.${_dashData[_dashData.gateWays[i]].listOfDevices[j]}.${_dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]].listOfParams[k]}.${holdValue.unit}.${holdValue.page}.${holdValue.index}`
                        objectFromServer_dash[holdAbit] = {displayName:holdValue?.dashName,displayColour:holdValue?.displayColour?holdValue.displayColour:'#9aaf9a',bckColour:holdValue?.bckColour?holdValue.bckColour:'#727272',enableOverChart:holdValue?.enableOverChart,enableOverPiChart:holdValue?.enableOverPiChart}
                        objectFromServer_select[holdAbit] = true
                        holdColorObject[`${_dashData.gateWays[i]}.${_dashData[_dashData.gateWays[i]].listOfDevices[j]}.${_dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]].listOfParams[k]}`] = {color: 'green', cursor: 'pointer', userSelect: 'none', paddingLeft: '1.1rem'}
                      }                 
                    }
                  }
                }
              }
            }
          }
        }catch(error){
          console.error(error)
        }
        setLiStyles(holdColorObject)
        setDashBoardNames(objectFromServer_dash)
        setListToFetchData(objectFromServer_select)
      }
    }

      
    const handelPlotList = (gatewayID,deviceName, parameterName) => {
      setLiStyles((prevStyles) => {
        const currentStyle = prevStyles[`${gatewayID}.${deviceName}.${parameterName}`];
        const newStyle = {};

        if (!currentStyle || currentStyle.color !== 'green') {
          newStyle[`${gatewayID}.${deviceName}.${parameterName}`] = { color: 'green',cursor:'pointer',userSelect: 'none', paddingLeft: '1.1rem' };
        }else{
          newStyle[`${gatewayID}.${deviceName}.${parameterName}`] = { color: 'black',cursor:'pointer',userSelect: 'none', paddingLeft: '1rem'};
        }

        return {
          ...prevStyles,
          ...newStyle,
        };
      });
    }; 
  
    useEffect(()=>{
      if(activeSiteData&&gatewayParam&&(!updateDashInfo)){
        setDrivers(gatewayParam[activeSiteData?.siteID]?.payLoad)
        handleDashSettingsFromServer(gatewayParam[activeSiteData?.siteID]?.dashSettings)
      }
    },[activeSiteData,gatewayParam,])
    
    useEffect(()=>{
      if(updateDashInfo){
        let _holdactivePram = {...gatewayParam}
        _holdactivePram[activeSiteData.siteID].dashSettings = processToSendDashData()
        dispatch({type:'SET_GW_PARAM',payload:_holdactivePram})
        setUpdateDashInfo(false)
      } 
    },[updateDashInfo])
        
    const handelOnclick_li=(event,value,index,indexPage,deviceIndex,paramIndex)=>{
      let thisArray = {...listToFetchData}
      if(thisArray!==undefined)(!thisArray[value])?(thisArray[value]=true):(thisArray[value]=false)
      setListToFetchData(thisArray)
    }
   
    const closeForm = () => {
      dispatch({ type: 'SET_FORM_FLAG', payload: null });
      dispatch({ type: 'SET_FORMOPEN_FLAG', payload: null });
    };
    
    useEffect(() => { // todo add all the function which has to run when the component is closed
      // Your function to run when the component is mounted 
      // Cleanup: Remove the side effect when the component is unmounted
      return () => {
        // the component is unmounted
      };
    }, []);

    

    const processToSendDashData = () =>{
      const listOfDashConfig = Object.entries(dashBoardNames)
      const listCount = listOfDashConfig.length
      let objectToSend = {}
      let listOfGateWays = [] // this is so that the backend can use the class without needing to do extra processing
      let listOfDevices = {}
      for(let i = 0;i<listCount;i++){
        const infoArray =  listOfDashConfig[i][0].split('.')
        
        if(listToFetchData[listOfDashConfig[i][0]]){ // only when parameter is active
          if(objectToSend[infoArray[0]]===undefined){objectToSend[infoArray[0]]={};listOfGateWays.push(infoArray[0]);objectToSend[infoArray[0]].listOfDevices = []}
          if(objectToSend[infoArray[0]][infoArray[1]]===undefined){objectToSend[infoArray[0]][infoArray[1]]={};objectToSend[infoArray[0]].listOfDevices.push(infoArray[1]);objectToSend[infoArray[0]][infoArray[1]].listOfParams = []}
          if(objectToSend[infoArray[0]][infoArray[1]][infoArray[2]]===undefined){objectToSend[infoArray[0]][infoArray[1]][infoArray[2]]={};objectToSend[infoArray[0]][infoArray[1]].listOfParams.push(infoArray[2]);}
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].unit = infoArray[3]
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].page = parseInt(infoArray[4],10)
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].index = parseInt(infoArray[5],10)
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].dashName = listOfDashConfig[i][1].displayName
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].displayColour = listOfDashConfig[i][1].displayColour
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].bckColour = listOfDashConfig[i][1]?.bckColour
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].enableOverChart = listOfDashConfig[i][1]?.enableOverChart
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].enableOverPiChart = listOfDashConfig[i][1]?.enableOverPiChart
        }
      }
      objectToSend.gateWays = listOfGateWays
      return objectToSend
    }

    const sendDashDataToServer = async ()=>{
      let payLoad =  processToSendDashData()
      payLoad.customSection = ovCustomSection
      console.log("this is what we are sending to the server:",payLoad)
      const rootUrl = window.location.origin; 
      const response = await fetch(`${rootUrl}/api/sites/dashData`, {
        method: 'POST',
        body: JSON.stringify({data:payLoad,site:activeSiteData.siteID}),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()
      
      if (!response.ok) {
        setError(json.error)
        
      }
      if (response.ok) {
        //dispatch({type:'SET_GW_PARAM',payload:})
        setUpdateDashInfo(true)
      }
    }

    return (
    <div className="site"> 
      <div className='sitePage-flex'>    
        <div className='sitePage-container'>
          {(drivers !== null && activeSiteData) ? (
            activeSiteData.gateways.map((gateway, index) => (
              <div key={gateway?.gatewayID}>
                <h1>{gateway?.gatewayID}</h1> 
                {drivers[gateway?.gatewayID].map((page, indexPage) => (
                  <ul key={`${indexPage}_${gateway?.gatewayID}`}>
                    {page !== null ? (
                      page.map((device, deviceIndex) => (
                        <li key={deviceIndex}>
                          <div className='dashboard-button' key={`${device.deviceName}_${deviceIndex}`} onClick={()=>deviceNameRes(`${device.deviceName}_${deviceIndex}`)} style = {{paddingLeft:'2rem',cursor:'pointer',userSelect: 'none'}}>{device.deviceName}</div>
                          {true?
                          <div className={`paramList ${deviceList?.[`${device.deviceName}_${deviceIndex}`]?"paramList-open":"paramList-closed"}`}>
                            <ul>
                              {device.parameters.map((param, paramIndex) =>{ 
                                  const chartID = `${gateway.gatewayID}.${device.deviceName}.${param.parameterName}`
                                  const extendedID = `${chartID}.${param.unit}.${indexPage}.${param.deviceValue}`
                                  return <li
                                    key={paramIndex}
                                    value = {`${extendedID}`}
                                    style={(liStyles[chartID]||{paddingLeft: '1rem'})}
                                    onClick={(event) => {
                                      handelPlotList(gateway.gatewayID,device.deviceName, param.parameterName);
                                      /*setLastClickedLi(`${gateway.gatewayID}.${device.deviceName}.${param.parameterName}`);*/
                                      handelOnclick_li(event,extendedID,index,indexPage,deviceIndex,paramIndex);
                                    }}
                                  >
                                    {param.parameterName}
                                  </li>
                              })}
                            </ul>
                          </div>
                          :<></>}
                        </li>
                      ))
                    ) : null}
                  </ul>
                ))}
              </div>
            ))
          ) : (
            <ComponentLoading/>
          )}
        </div>
        <div className='sitePage-selectedContainer'>
          <div>
            <button onClick={()=>{setConfigSel('conDash')}}>Configure Dasboard</button>
            <button onClick={()=>{setConfigSel('conOver')}}>Configure Overview</button>
            
            {(configSel==='conDash')&&<h1>{'Configure Dashboard'}</h1>}
            {(configSel==='conOver')&&<h1>{'Configure Overview'}</h1>}
          </div>
            <div className='sitePage-timeContainer'>
              <SelectedParameters activeSiteData={activeSiteData} listToFetchData={listToFetchData} configSel={configSel} dashBoardNames={dashBoardNames} setDashBoardNames={setDashBoardNames} listOfSelected={listOfSelected} setListOfSelected = {setListOfSelected}  />
            </div>
            <br></br>
            {(configSel==='conOver')&&<h1>{'Custom Section'}</h1>}
            {(configSel==='conOver')&&<div className='sitePage-timeContainer' style={{backgroundColor:'red',height:'20vh'}}>
              <ul>
                {ovCustomSection?.map((item,index)=>{return <li>
                  {
                    <input type='text' value={item?.label} onChange={(e)=>setOvCustomSection(onChangelabel(e,ovCustomSection,index))}/>
                    }{
                      <select onChange={(e)=>setOvCustomSection(onChangeOperation(e,ovCustomSection,index))} value={ovCustomSection[index].operation[0]}>
                        {_operationList?.map((item2,index2)=>(<option key={index2} value={index2} >{item2[1]}</option>))}
                      </select>
                    }{
                      <input type='number' value={item?.dayBackDate} onChange={(e)=>setOvCustomSection(onChangeBackDate(e,ovCustomSection,index))} />
                    }{
                      <select value={ovCustomSection[index].parameter} onChange={(e)=>setOvCustomSection(onChangeParam(e,ovCustomSection,index))}>{listOfSelected?.map((item2)=>{
                        return<option value={item2[1]}>{item2[0]}</option>
                      })}</select>
                    }{
                      <select value={ovCustomSection[index].parameterMetric} onChange={(e)=>setOvCustomSection(onChangeParamMetric(e,ovCustomSection,index))}>{ _paramMetric?.map((item2)=>{
                        return<option value={item2[0]}>{item2[1]}</option>
                      })
                      }</select>
                    }{
                      <input onChange={(e)=>setOvCustomSection(onChangeKeepUnit(e,ovCustomSection,index))} type='checkbox' checked = {ovCustomSection[index].keepUnit}></input>
                    }{
                      <input type='text' onChange={(e)=>setOvCustomSection(onChangeSetNewUnit(e,ovCustomSection,index))} defaultValue={ovCustomSection[index].newUnit}></input>
                    }
                  </li>})
                }
              </ul>
            </div>}
            {(configSel==='conOver')&&<button className = 'defaultButton' onClick={()=>addCustomSection()}>Add Custom Section</button>}
            <button className = 'defaultButton' onClick={()=>sendDashDataToServer()}>Update Dasboard</button>
          
          </div>
      </div>
      
    </div>
  );


  
};


export default ConfigureDash;

function addCustomSection(){

}

let _ovCustomSection = [{
  label:"Total CO saved", // Description of the info to display
  operation: [2], // example divide add multiply etc
  factor:[1], // what we operate with
  dayBackDate:2, // how many days should it go back
  parameter: '', // this is the data parameter eg InverterPower
  parameterMetric:'', // area of the chart
  keepUnit:true, 
  newUnit:'t'
}]

function onChangeSetNewUnit(e,ovCustomSection,index){
  let _holdObj = _.cloneDeep(ovCustomSection)
  _holdObj[index].newUnit = e.target.value
  return _holdObj
}

function onChangeKeepUnit(e,ovCustomSection,index){
  let _holdObj = _.cloneDeep(ovCustomSection)
  _holdObj[index].keepUnit = e.target.value
  return _holdObj
}

function onChangeParamMetric(e,ovCustomSection,index){
  let _holdObj = _.cloneDeep(ovCustomSection)
  _holdObj[index].parameterMetric = e.target.value
  console.log("this is the target",e.target)
  return _holdObj
}

function onChangeParam(e,ovCustomSection,index){
  let _holdObj = _.cloneDeep(ovCustomSection)
  _holdObj[index].parameter = e.target.value
  console.log("this is the target",e.target)
  return _holdObj
}

function onChangeOperation(e,ovCustomSection,index){
  let _holdObj = _.cloneDeep(ovCustomSection) 
  _holdObj[index].operation = e.target.value
  return _holdObj
}

function onChangelabel(e,ovCustomSection,index){
  let _holdObj = _.cloneDeep(ovCustomSection)
  _holdObj[index].label = e.target.value
  return _holdObj  
}

function onChangeBackDate(e,ovCustomSection,index){
  let _holdObj = _.cloneDeep(ovCustomSection)
  _holdObj[index].dayBackDate = e.target.value
  return _holdObj 
}


const SelectedParameters = React.memo(({activeSiteData,dashBoardNames,setDashBoardNames,listToFetchData,configSel,listOfSelected,setListOfSelected}) =>{

  const [listComplete,setListComplete] =useState(false)

  const updateInfo=(e,item,type)=>{
    
    let holdArray = {...dashBoardNames}
    console.log("Here are the dasboards names",dashBoardNames)
    console.log("here is the array",holdArray)
    if(type==='name'){
      if(holdArray[item]===undefined){holdArray[item]={}}
      holdArray[item]['displayName'] = e.target.value
      setDashBoardNames(holdArray)
    }else if(type==='colour'){
      if(holdArray[item]===undefined){holdArray[item]={}}
      holdArray[item]['displayColour' ]= e.target.value
      setDashBoardNames(holdArray)
    }else if(type==='bckColour'){
      if(holdArray[item]===undefined){holdArray[item]={}}
      holdArray[item]['bckColour' ]= e.target.value
      setDashBoardNames(holdArray)
    }else if(type==='enableOverChart'){
      holdArray[item]['enableOverChart'] = e.target.checked
      setDashBoardNames(holdArray)
    }else if(type==='enableOverPiChart'){
      holdArray[item]['enableOverPiChart'] = e.target.checked
      setDashBoardNames(holdArray)
    }
  }
  
  let thisArray = []

  const  handleListOfSelected = (item,count,index)=> {
    let _listOfSelected= _.cloneDeep(listOfSelected)
    const _array = item[0].split('.')
    const _displayString = `${_array[0]} ${_array[1]} ${_array[2]}`
    _listOfSelected.push([_displayString,item[0]])
    
    thisArray.push([_displayString,item[0]])
    
    console.log("This is the list we are looking for: ",thisArray)
    console.log("This is the count:",count," and this is the index:",index)

    if(count===(index+1)&&!listComplete){setListComplete(true); setListOfSelected(thisArray);}
  }

  useEffect(()=>{
    if(listComplete){
      //let _equalFlag = true
      //listOfSelected?.map((item,index)=>{ if(!(item[1]===thisArray[1]))_equalFlag=false})
      
      console.log("Setting the array to the following:",thisArray)
    }
    console.log("Setting the array to the following 2:",thisArray)
  },[listComplete])

  
  return(<div>
  {(activeSiteData!==null&&activeSiteData&&activeSiteData!==undefined)?activeSiteData?.gateways.map((gateway,gatewayIndex)=>{
    if((listToFetchData !== null && listToFetchData !== undefined)){const _entryArray = Object?.entries(listToFetchData)
      const _entryCount = _entryArray.length
      return(<div key={`gatewayDiv${gatewayIndex}`}>
        <h2 key = {`gatewayH2${gatewayIndex}`}>{gateway.gatewayID}</h2>
        
          <ul key={`gatewayUl${gatewayIndex}`} style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'column' }}>
            {_entryArray?.map((item,index) => {
              if(!listComplete)handleListOfSelected(item,_entryCount,index) 
              // setListOfSelected(listOfSelected?false:true)
              return((item[1] && (gateway.gatewayID === item[0].split('.')[0])) ? (
                <li key={`gatewayLi${gatewayIndex}${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <span key = {`gatewaySpan${gatewayIndex}${index}`}>{(item[1]) ? item[0].replace(`${gateway.gatewayID}.`, '').replace(`.${item[0].split('.')[3]}.${item[0].split('.')[4]}.${item[0].split('.')[5]}`, '') : null}</span>
                  
                  {(configSel==='conDash')&&
                    <>
                      <input key = {`gatewayInput${gatewayIndex}${index}`} type='text'  value={dashBoardNames[item[0]]?dashBoardNames[item[0]]?.displayName:'addName'} onChange={(e) => updateInfo(e, item[0],'name')} style={{ marginLeft: '8px', color: dashBoardNames[item[0]]?'black':'grey',fontStyle:dashBoardNames[item[0]]?'':'italic'}} />
                      <input type="color" value={dashBoardNames[item[0]]?.displayColour} onChange={(e) => updateInfo(e, item[0],'colour')}/>
                      <input type="color" value={dashBoardNames[item[0]]?.bckColour} onChange={(e) => updateInfo(e, item[0],'bckColour')}/>
                    </>
                  }
                  {(configSel==='conOver')&&
                    <>
                      <input type="checkbox" checked = {dashBoardNames[item[0]]?.enableOverChart} onClick={(e) => updateInfo(e, item[0],'enableOverChart')}></input>
                      <input type="checkbox" checked = {dashBoardNames[item[0]]?.enableOverPiChart} onClick={(e) => updateInfo(e, item[0],'enableOverPiChart')}></input>
                    </>
                  }
                </li>
              ) : null)
            })}
          </ul>
      </div>)}
    return null
    }):<></>}
  </div>)
})