import React from "react";
import { useSitesContext } from "../../../hooks/useSitesContext";
import { useLiveDataContext } from "../../../hooks/useLiveDataContext";
import { useSemiLiveDataContext } from "../../../hooks/useSemiLiveDataContext";
import { InlineLoading } from "../../../components/subcomp/InlineLoading";
import { unitConverter } from "../../../components/functionalComponents/unitConverter";

const LiveDisplay = ({argument})=>{
    const {liveChart} = useSemiLiveDataContext()
    const { gatewayParam } = useSitesContext()
    const { liveData } = useLiveDataContext();

    if(gatewayParam&&liveChart&&argument?.chartSelect){
        
        const gatewayID = argument.chartSelect.split('.')[0]
        const device = argument.chartSelect.split('.')[1]
        const param = argument.chartSelect.split('.')[2]
        let prop = `${gatewayParam[argument?.siteID]?.dashSettings[gatewayID][device][param].page}.${gatewayParam[argument.siteID]?.dashSettings[gatewayID][device][param].index}`
                
        return(
            <div id="liveDataDiv">{liveData?.[gatewayID]?(<>{handlingLiveData(liveData[gatewayID][prop]).live}</>):<InlineLoading/>}</div>
        )
    }    
    
}

export default LiveDisplay

function handlingLiveData(_liveData){
    if(!_liveData)return {live:'',load:''}
    const _data= unitConverter(_liveData.liveData,_liveData.unit)
    if(_liveData&&_data)return {live:`${_data.value.toFixed(2)} ${_data.unit}`,load:`${_liveData?.displayName} Loading... ${_data.unit}`}
  }