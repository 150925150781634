import React, { useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server';
import { unitConverter } from '../functionalComponents/unitConverter';
import { useSemiLiveDataContext } from '../../hooks/useSemiLiveDataContext';
const echarts = require('echarts')

// note that the argument is only for one gateway. another gateways should be on another chart

export const NestedTable = ({ obj }) => {
  const tableStyle = {
    borderCollapse: 'collapse',
    width: '100%',
  };

  const cellStyle = {
    padding: '0px',
  };

  const boldCellStyle = {
    ...cellStyle,
    fontWeight: 'bold',
  };

  const rows = [];

  Object.entries(obj).forEach(([mainKey, subObject], index) => {
    rows.push(
      <tr key={mainKey}>
        <th style={index === 0 ? boldCellStyle : cellStyle}>{mainKey}</th>
      </tr>
    );

    // Check if the subObject has nested objects
    if (typeof subObject === 'object' && Object.keys(subObject).length > 0) {
      Object.entries(subObject).forEach(([subKey, subValue]) => {
        rows.push(
          <tr key={`${mainKey}-${subKey}`}>
            <td style={cellStyle}>{subKey}</td>
            <td style={cellStyle}>{subValue}</td>
          </tr>
        );
      });
    }
  });

  return (
    <table style={tableStyle}>
      <tbody>{rows}</tbody>
    </table>
  );
};

const DataChart = React.memo(({argument})=>{
    const chartRef = useRef(null);
    const parameterCount = argument?.arrayList?.length
    const clean = !(argument?.clean)
    const selected = argument.selected
    let seriesArrays = []
    const {liveChart} = useSemiLiveDataContext();

    const toolTipFunction = (params)=>{
      const pointsCount = params.length
      const axisValueLabel = params[0].axisValueLabel
      let returnObj = {}
      for(let i=0;i<pointsCount;i++){
        const nameArray = params[i].seriesName.split('.')
        const deviceName = nameArray[0]
        const paramName = nameArray[1]
        const unit = nameArray[2]
        const type = nameArray[3]
        const _unitHold = unitConverter(params[i].value[1],unit)
        if(!returnObj[deviceName])returnObj[deviceName] = {}
        if(!returnObj[deviceName][paramName])returnObj[deviceName][paramName] = {unit:_unitHold.unit}
        returnObj[deviceName][paramName][type] = _unitHold.value
      }
      const deviceArray = Object.keys(returnObj)
      const deviceCount = deviceArray.length
      for(let i =0;i<deviceCount;i++){
        const paramArray = Object.keys(returnObj[deviceArray[i]])
        const paramCount = paramArray.length
        for(let j = 0;j<paramCount;j++){
          returnObj[deviceArray[i]][paramArray[j]] = `${selected?.avg?` | ${returnObj[deviceArray[i]][paramArray[j]].average}`:''}${selected?.max?` | ${returnObj[deviceArray[i]][paramArray[j]].max}`:''}${selected?.min?` | ${returnObj[deviceArray[i]][paramArray[j]].min}`:''} ${returnObj[deviceArray[i]][paramArray[j]].unit}`
        }
      }
      
      return `<div><div>${axisValueLabel} ${selected?.avg?" | Avg":""}${selected?.max?" | Max":""}${selected?.min?" | Min":""}</div>${ReactDOMServer.renderToString(<NestedTable obj={returnObj} />)}</div>`
    }

    for(let i =0;i<parameterCount;i++){
      let thisObj = {}
      try{
        if(argument.data[argument.arrayList[i][0]][argument.arrayList[i][1]].max){
          thisObj = {
              name: `${argument.arrayList[i][0]||null}.${argument.arrayList[i][1]}.${argument.arrayList[i][2]}.max`,
              type: 'line',
              smooth: false,
              symbol: 'none',
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#9aaf9a88'
                  },
                  {
                    offset: 1,
                    color: '#ffffff33'
                  }
                ])
              },
              lineStyle:{
                width: 1,
                color: '#9aaf9a'
              },
              data: argument.data[argument.arrayList[i][0]][argument.arrayList[i][1]].max.map((item,index)=>([(argument.data.time[index]+argument.timeZoneErr*60)*1000,item?item.toPrecision(4):item]))
          }
          seriesArrays.push(thisObj)
        }
        if(argument.data[argument.arrayList[i][0]][argument.arrayList[i][1]].min){
          thisObj = {
              name: `${argument.arrayList[i][0]}.${argument.arrayList[i][1]}.${argument.arrayList[i][2]}.min`,
              type: 'line',
              smooth: false,
              symbol: 'none',
              areaStyle: {},
              data: argument.data[argument.arrayList[i][0]][argument.arrayList[i][1]].min.map((item,index)=>([(argument.data.time[index]+argument.timeZoneErr*60)*1000,item?item.toPrecision(4):item])) 
          }
          seriesArrays.push(thisObj)
        }
        if(argument.data[argument.arrayList[i][0]][argument.arrayList[i][1]].average){
          thisObj = {
              name: `${argument.arrayList[i][0]}.${argument.arrayList[i][1]}.${argument.arrayList[i][2]}.average`,
              type: 'line',
              smooth: false,
              symbol: 'none',
              areaStyle: {},
              data: argument.data[argument.arrayList[i][0]][argument.arrayList[i][1]].average.map((item,index)=>([(argument.data.time[index]+argument.timeZoneErr*60)*1000,item?item.toPrecision(4):item])) 
          }
          seriesArrays.push(thisObj)
        }
      }catch(error){
        console.error(error)
      }
    }
  useEffect(() => {
    // Initialize the chart when the component mounts
    const myChart = echarts.init(chartRef.current);    
    const option = {
      
      areaStyle:{
        connectNulls: true,
      },
      grid:{
        left:clean?17:65,
        right:clean?17.2:15,
        show:false
      },
      legend:{
        left: 10,
        top: '20rem',
        type: 'scroll',
        orient: 'horizontal',
      },
      animation: false,
      tooltip: {
        trigger: 'axis', // or 'item' for other types of charts
        axisPointer: {
            type: 'cross' // or 'line' or 'shadow' or other pointer types
        },
        formatter: function(params) {
            
            return toolTipFunction(params);
        },
        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Tooltip background color
        position: function (pos, params, el, elRect, size) {
          let obj = { top: 10 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        }
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: {},
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'time',
        axisLabel:{
          color: clean?'black':'grey',
          fontSize: '1rem',
          hideOverlap: true,
        },
        axisLine:{
          show:false
        }
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%'],
        axisLabel:{
          color: clean?'black':'grey',
          inside:clean?true:false,
          fontSize: '1rem',
          hideOverlap: true,
          formatter:(value,index)=>{
            const holdData = unitConverter(value,'')
            return `${holdData.value}${holdData.unit}` // the unit should be added to the x axis so that it can accommodate all the charts plotted on the grid 
          }
        },
        zlevel:3,
        splitLine:{
          show:false
        }
      },
      dataZoom: !clean?[
        {type: 'slider',show: true,xAxisIndex: [0],start: 0,end: 100},        
        {type: 'inside',xAxisIndex: [0],start: 0,end: 100},
      ]:[
        {type: 'inside',xAxisIndex: [0],start: 0,end: 100},
      ],
      
      series: seriesArrays,
      
    };

    // Set the option and render the chart
    myChart.setOption(option);


    // Ensure the chart is properly disposed of when the component unmounts
    return () => {
      myChart.dispose();
    };
  }, [argument]);

  return <> <div ref={chartRef} style={{ width: '100%', height: '100%' }} /> </>;

})

export default DataChart;