import React, { useEffect, useRef,useState } from 'react';
import { useStaticDataContext } from '../../hooks/useStaticDataContext';
import { useSemiLiveDataContext } from '../../hooks/useSemiLiveDataContext';
import { unitConverter } from '../functionalComponents/unitConverter';
import { useUserContext } from '../../hooks/useUserContext';
import { useSitesContext } from '../../hooks/useSitesContext'; 
import LiveDisplay from "../../pages/site/components/LiveDisplay"

const echarts = require('echarts');

// note that the argument is only for one gateway. another gateways should be on another chart
const JustTheChart = React.memo(({ argument,chartArray }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  
  useEffect(() => {
    // Initialize the chart when the component mounts
    chartInstanceRef.current = echarts.init(chartRef.current);

    const option = {
        animation: false,
        grid:{
          left:6,
          bottom:0,
          top:30,
          right: 0,
          containLabel:true
        },
        xAxis: {
            axisLine:{show:false},
            type: 'time',
            axisTick:{show:false},
            axisLabel:{
                margin:5,
                fontSize:'1.5rem',
                color: "black"
            },
        },
        yAxis: {
            boundaryGap: [0, '100%'],
            splitLine:{show:false},
            axisLine:{show:false},
            axisLabel:{
                margin: 5,
                fontSize:'1.5rem',
                color:'black',
                left:0,
                formatter:(value,index)=>{
                  const holdValue = unitConverter(value,'')
                  const intHold = holdValue?.value.toFixed()
                  if(holdValue?.value%intHold)return ''
                  return index===0?"":`${holdValue?.value}`
                }
            },
            max:'dataMax'
        }, 
        series: chartArray,
    };

    // Set the option and render the chart
    chartInstanceRef.current.setOption(option);

    // Resize the chart when the window resizes
    const handleResize = () => {
      chartInstanceRef.current.resize();
    };
    window.addEventListener('resize', handleResize);

    // Ensure the chart is properly disposed of and event listener is cleaned up when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      chartInstanceRef.current.dispose();
    };
  }, [argument,chartArray]);

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }}/>;
});

// =============Pi chart==================

const JustThePiChart = React.memo(({argument,set,title})=>{
  const { activeSiteData,userRole } = useUserContext();
  const { gatewayParam } = useSitesContext();
  const [dataPie,setDataPie] = useState({})
  const { liveChart } = useSemiLiveDataContext();
  const chartInstanceRef = useRef(null);
  
  useEffect(()=>{
    if(gatewayParam&&activeSiteData&&liveChart){
      let _listOfPiWedg = getListOfCharts(activeSiteData,gatewayParam,liveChart)?.listOfPiWedg
      let missingDataFlag = true;
      let _wedgCount = _listOfPiWedg?.length
      let _dataSet = []
      let _wedgColour = []
      let _graphicString = ''
      let _graphicRich ={}

      for(let i = 0;i<_wedgCount;i++){
        let _paramObj = argument?.dataset?.[_listOfPiWedg[i][0]]?.data
        let _styleObj = argument?.dataset?.[_listOfPiWedg[i][0]]?.addSet
        if(_paramObj?.analysis[set]?.area!==0)missingDataFlag = false;
        const _valUnCon = unitConverter(_paramObj?.analysis[set]?.area/60,_listOfPiWedg[i][1]?.dashUnit);
        _dataSet.push({
          value:_paramObj?.analysis[set]?.area,
          name:`${_valUnCon?.value?.toFixed(2)} ${_valUnCon?.unit}h`
        })
        _wedgColour.push(`${_styleObj?.colour}`)
        
        _graphicString +=`${!i?'':`\n`}{${i}|${_valUnCon?.value?.toFixed(2)} ${_valUnCon?.unit}h}`
        _graphicRich[`${i}`] = {
          fill:_styleObj?.colour,
          fontSize:20,
          fontWeight:'bold'
        }
      }
      if(missingDataFlag)_dataSet = []
      setDataPie({wedgColour:_wedgColour,dataSet:_dataSet,graphic:{text:_graphicString,rich:_graphicRich}})
      
    }
    
  },[gatewayParam,liveChart])
  const chartRef = useRef(null);

 
  /////////////
  useEffect(() => {
    chartInstanceRef.current = echarts.init(chartRef.current);
    const option = {
      title: {
        text: title,
        left: 'center'
      },
      graphic: {
        type:'text',
        left:'2%',
        top:'middle',
        align:'right',
        style:{
          text: dataPie?.graphic?.text,
          rich: dataPie?.graphic?.rich,
          // backgroundColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, dataPie?.graphic?.color),
          borderRadius: 4,
          padding: [5,10],
          opacity:0.8
        },
        z:3
      },
      animation:false,
      series: [{
        type:'pie',
        raduis:'70%',
        right:'0%',
        center: ['70%', '50%'],
        data:dataPie?.dataSet,
        
        label: {
          show: false,
          position: 'center'
        },
      
      }],
      color:dataPie?.wedgColour
    }
    // Initialize the chart when the component mounts
    chartInstanceRef.current = echarts.init(chartRef.current);

    // Set the option and render the chart
    chartInstanceRef.current.setOption(option);

    // Resize the chart when the window resizes
    const handleResize = () => {
      chartInstanceRef.current.resize();
    };
    window.addEventListener('resize', handleResize);

    // Ensure the chart is properly disposed of and event listener is cleaned up when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      chartInstanceRef.current.dispose();
    };
  }, [argument,dataPie]);

  return <div ref={chartRef} style={{ width: '90%',height: '100%' }}/>;
})

const OverviewChart = React.memo(({ argument,chartDataView }) => {
  const { activeSiteData,userRole } = useUserContext();
  const { gatewayParam } = useSitesContext();
  const { liveChart } = useSemiLiveDataContext();
  const [listOfLiveID,setListOfLiveID] = useState();
  const [chartArray,setChartArray] = useState();

  useEffect(()=>{
    if(gatewayParam){

      let _listOfCharts = getListOfCharts(activeSiteData,gatewayParam,liveChart).listOfCharts
      setListOfLiveID(_listOfCharts)
      // test it"
      let plotArr = []
      let _chartCount = _listOfCharts.length
      for(let a=0;a<_chartCount;a++){
        let _paramObj = argument?.dataset[_listOfCharts[a][0]]?.data
        let _styleObj = argument?.dataset[_listOfCharts[a][0]]?.addSet
        let _dataArr = _paramObj?.rangeClean[0]?.map((item,index)=>([_paramObj?.domain[0][index],item]))
        plotArr.push({
          type:'line',
          symbol:'none',
          data:_dataArr,
          smooth:0.3,
          lineStyle:{
            color: _styleObj?.colour,
            width: 1
          },
          areaStyle:{
            opacity:0.5,
            color:_styleObj?.colour
          }
        })
      }
      setChartArray(plotArr)
    }
  },[gatewayParam,liveChart])


  const piCartTitle = ['Today', 'Yesterday'];
  const piChartTiteCoutn = piCartTitle.length
 
  if (argument && argument?.dataset) {
    return (
      <>
        <div className="content overview">
          <div style={{ position: 'relative', width: '100%', height: '100%', zIndex: 2 }}>
            <JustTheChart argument={argument} chartArray = {chartArray} />
          </div>
        </div>
          <div id="overViewBar" className='glossy-frame'>
            <div id="liveOverview">
            {listOfLiveID&&chartDataView&&activeSiteData&&listOfLiveID.map((item,index)=>(<div className='liveOvTab' style={{color:argument?.dataset[item[0]]?.addSet?.colour}}><div className="liveOvLable">{argument?.dataset?.[item[0]]?.data?.name}</div><LiveDisplay argument = {{data:chartDataView?.[item[0]]?.data,clean:false,chartSelect:item[0],siteID:activeSiteData.siteID,addSet:chartDataView?.[item[0]]?.addSet}}/></div>))}
            </div>
            <div className='pieChartContainer'>
            {piCartTitle.map((i, n) => (
              <div key={n} style={{ height: `${100/piChartTiteCoutn}%`, width: '100%' }}>
                <JustThePiChart argument={argument} set={n} title={i} />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
});


export default OverviewChart;

function getListOfCharts(activeSiteData,gatewayParam,liveChart){
  let listOfCharts = []
  let listOfPiWedg = []

  const _gatewaysArr = gatewayParam?.[activeSiteData?.siteID]?.dashSettings?.gateWays
  const _dashSettings = gatewayParam?.[activeSiteData?.siteID]?.dashSettings
  const _liveChartInfo = liveChart?.[activeSiteData?.siteID]
  const _loopOne = _gatewaysArr?.length
  
  for(let i =0;i<_loopOne;i++){
    const _deviceArr = _dashSettings?.[_gatewaysArr[i]]?.listOfDevices
    const _loopTwo = _deviceArr?.length
    const _dispInfoObj = _liveChartInfo?.[_gatewaysArr[i]]?.displayInfo

    for(let j = 0;j<_loopTwo;j++){
      const _paramArr = _dashSettings?.[_gatewaysArr[i]]?.[_deviceArr[j]]?.listOfParams
      const _loopThree = _paramArr?.length
      
      for(let k = 0;k<_loopThree;k++){
        const _paramSettings = _dashSettings?.[_gatewaysArr[i]]?.[_deviceArr[j]]?.[_paramArr[k]]
        const _chartId = `${_gatewaysArr[i]}.${_deviceArr[j]}.${_paramArr[k]}` 
        if(_paramSettings?.enableOverChart){
          listOfCharts.push([_chartId,_dispInfoObj?.[_chartId]])
        }
        if(_paramSettings?.enableOverPiChart){
          listOfPiWedg.push([_chartId,_dispInfoObj?.[_chartId]])
        }
      }
    }
  }

  return {listOfCharts,listOfPiWedg}
  
}

function contrastingColor(color) {
  return (luma(color) >= 165) ? '#000000' : '#ffffff';
}

function luma(color) { 
  let rgb = (typeof color === 'string') ? hexToRGBArray(color) : color;
  return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
}

function hexToRGBArray(color) {
  // Remove the '#' if it exists
  if (color.charAt(0) === '#') {
      color = color.slice(1);
  }

  // Handle shorthand hex codes (e.g., #abc)
  if (color.length === 3) {
      color = color.charAt(0) + color.charAt(0) +
              color.charAt(1) + color.charAt(1) +
              color.charAt(2) + color.charAt(2);
  } else if (color.length !== 6) {
      throw('Invalid hex color: ' + color);
  }

  let rgb = [];
  for (let i = 0; i <= 2; i++) {
      rgb[i] = parseInt(color.substr(i * 2, 2), 16);
  }

  return rgb;
}

