import React, { useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server';
import { useStaticDataContext } from '../../hooks/useStaticDataContext'
import { useSemiLiveDataContext } from '../../hooks/useSemiLiveDataContext';
import { unitConverter } from '../functionalComponents/unitConverter';
import { toSigFigs } from '../functionalComponents/sigFig';
const echarts = require('echarts')


// note that the argument is only for one gateway. another gateways should be on another chart
const DataChart = React.memo(({argument,hide})=>{
  const { liveChart } = useSemiLiveDataContext(); 
  const { isLanscape } = useStaticDataContext();
  const chartRef = useRef(null);
  const paramObj = liveChart?.[argument?.siteID]?.[argument?.chartSelect?.split('.')[0]]?.displayInfo[argument?.chartSelect]
  const unit = paramObj?.dashUnit;
  const dashName = paramObj?.dashName;

  const maxRange = argument?.data?.analysis?.max
  const averageObj = unitConverter(argument?.data?.analysis?.average,unit)
  const minObj = unitConverter(argument?.data?.analysis?.min,unit)
  const maxObj = unitConverter(argument?.data?.analysis?.max,unit)
  
  const toolTipFunction=(params)=>{
    let _read = {value:null,unit:''}
    if(params[0]?.value[1])_read = unitConverter(params[0]?.value[1],unit)

    
    return `
        <div>${params[0].axisValueLabel}</div>
        <div>${liveChart?.[argument?.siteID]?.[argument?.chartSelect?.split('.')[0]]?.displayInfo[argument.chartSelect].dashName}  ${params[0]?.value[1]?_read?.value?.toFixed(2):null} ${_read?.unit}</div>
        `
  }

  
    
  useEffect(() => {
    const windowWidth =window.innerWidth
    const windowHeight =window.innerHeight
    // Initialize the chart when the component mounts
    const Chart = echarts.init(chartRef.current);
    const option = { // #9aaf9a
      title:{
        show: isLanscape?'true':'false',
        text: dashName&&isLanscape?dashName:'',
        left: '1%',
        top: '1%',
        subtext:''
      },
      graphic: [
        {
            type: 'text',
            left: `${windowWidth/(windowHeight)+(argument.clean?0:-2*windowHeight/windowWidth+6)}%`,
            top: function(){
              if(!argument?.data?.rangeClean)return "6%"
              const dataArray = argument?.data?.rangeClean
              if(dataArray[0]===null)return "6%"

              let whileIndex = 0;
              let sectionMax = dataArray[0];
              let sectionMin = sectionMax;
              while(whileIndex<20){
                if(sectionMax<dataArray[whileIndex])sectionMax = dataArray[whileIndex];
                if(sectionMin>dataArray[whileIndex])sectionMin = dataArray[whileIndex];
                whileIndex++
              }
                      
              let position = 6;
              if(sectionMax/maxRange>0.85){
                position = (1-sectionMin/maxRange)*100+10;
              }else position = 8;
              
              return `${position}%`
            }(),
            style: {
                text: `Average:${averageObj?.value?.toFixed(2)} ${averageObj?.unit}\nMaximum: ${maxObj?.value?.toFixed(2)} ${maxObj?.unit} \nMinimum:${minObj?.value?.toFixed(2)} ${minObj?.unit}`,
                fill: '#384975', 
                backgroundColor: '#9aaf9a',
                borderRadius: 4,
                padding: [5,10],
            },
            z:1000
        }
    ],
      grid:{
        left:argument.clean?0:10,
        right:0,
        bottom:3,
        top:argument.clean?10:30,
        containLabel: argument.clean?false:true
      },
      legend:{
        left: 10,
        top: '20rem',
        type: 'scroll',
        orient: 'horizontal',
        show:argument.clean?false:false
      },
      animation: false,
      areaStyle:{
        color:'red'
      },
      tooltip: {
        trigger: 'axis', // or 'item' for other types of charts
        backgroundColor: '#9aaf9a',

        textStyle:{color:'#384975'},
        borderWidth: 0,
        formatter: function(params) {
            return toolTipFunction(params);
        },
        position: function (pos, params, el, elRect, size) {
          let obj = { top: 10 };
          
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] * 0.25 || pos[0] < size.viewSize[0] * 0.75)]] = 30;

          return obj;
        }
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: {},
          saveAsImage: {}
        },
        show:argument.clean?false:true
      },
      xAxis: {
        type: 'time',
        axisLine:{
          show:false
        },
        axisTick:{
          show:false
        },
        axisLabel:{
          margin:argument.clean?-10:10,
          formatter: (value, index) => {
            const holdDate = new Date(value).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
            return ((holdDate==='24:00'||holdDate==='00:00'))?'00:00':holdDate
          },
        },
        show:argument.clean?true:true
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%'],
        show:argument.clean?false:true,
        splitLine:{show:false},
        axisLabel:{
          formatter:(value,index)=>{
            const holdValue = unitConverter(value,unit)
            const intHold = holdValue?.value.toFixed()
            if(holdValue?.value%intHold)return ''
            return `${holdValue.value}${holdValue.unit}`
          }
        },
        max:'dataMax'
      },      
      
      series: [
        {
          data:argument.userRole==='admin'?argument?.data?.range.map((item,index)=>([argument?.data?.domain[index],item])):argument?.data?.rangeClean.map((item,index)=>([argument?.data?.domain[index],item])),
          type:'line',
          smooth:0.3,
          connectNulls:false,
          symbol:'none',
          lineStyle:{
            width: 1,
            color: '#384975',
          },
          areaStyle:{
            opacity: 0.5,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#9aaf9aff'
              },
              {
                offset: 1,
                color: '#9aaf9a11'
              }
            ]),
          }
        }
      ],
    };

    // Set the option and render the chart
    Chart.setOption(option);
    
    // Ensure the chart is properly disposed of when the component unmounts
    return () => {
      Chart.dispose();
    };
  }, [argument,hide]);

  return <div ref={chartRef} style={{ width: '100%', height: hide?'0':(argument.clean?'40vh':'100%') }} />;

})

export default DataChart;

function cropChartDataX(data,threshold,reflect){
  if(!data)return null
  let returnArray = [];
  const dataCount = data.length;
  let offsetStart = 0;
  let upTo = 0;
  let index = 0;
  let halCnt = dataCount/2;
  while(index<halCnt&&Math.abs(data[index++][1])<threshold){}
  if(!reflect){
    offsetStart = index;
    index = dataCount-1
    while(index>halCnt&&Math.abs(data[index--][1])<threshold){}
    upTo = index;
  }
  upTo = dataCount - offsetStart
  index = 0;
  for(let i = offsetStart;i<upTo;i++){
    returnArray[index++] = data[i]
  }
  return returnArray
}