import React, { useState, useEffect, useCallback} from 'react';
import { useUserContext } from '../../hooks/useUserContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useSitesContext } from '../../hooks/useSitesContext';
import DataChart from './SitePageDetails_Chart'
import { useStaticDataContext } from '../../hooks/useStaticDataContext';
import { useUserSiteContext } from '../../hooks/useUserSite';
import './site.css';
import Right from '../../svg_components/arrows/right.svg'
import { ComponentLoading } from '../subcomp/InlineLoading';

const ANALYTICS_RES = 5
const FETCH_LIM = 20 // the limit of params at one time

const SitePageDeviceList = () => {
    const { dispatch, formFlag, gatewayParam } = useSitesContext();
    const { timeOffset,timeZoneErr,isLanscape } = useStaticDataContext();
    const { userSite } = useUserSiteContext();
    const { user } = useAuthContext();
    const { activeSiteData,userRole } = useUserContext();
    const [error, setError] = useState(null);
    const [drivers,setDrivers] = useState(null);
    const [gatewayDataInfo,setGatewayDataInfo] = useState(null)
    const [liStyles, setLiStyles] = useState({});
    //const [lastClickedLi, setLastClickedLi] = useState(null);
    const [listToFetchData,setListToFetchData] = useState(null)
    const [dataFetchTime,setDataFetchTime] = useState(null)
    const [chartData,setChartData] = useState(null)
    const [chartArrayList,setChartArrayList] = useState(null)
    const [selectedCount,setSelectedCount] = useState(0)
    const [deviceList,setDeviceList] = useState({})
    const [overlay,setOverlay] = useState(false);
    const [dataTypeSelect,setDataTypeSelect] = useState({max:false,min:false,avg:true})
    const [fetchingData,setFetchingData] = useState(false)
    const [qdSelect,setqdSelect] = useState(0) // setting to today

    const selectParamMessage = <>Please select parameters from the parameter group on the left</>;

      useEffect(()=>{
        const listArray = listToFetchData?Object.entries(listToFetchData):[]
        const selectedCount =  listArray.length
        let counter = 0
        for(let i =0;i<selectedCount;i++){
          if(listArray[i][1]===true)counter++
        }
        setSelectedCount(counter)
      },[listToFetchData])
      useEffect(()=>{
        if(activeSiteData&&gatewayParam){ // the liveChart hook is adopted from the dasboard, this is so that we have the time offset to plot the chart according to the site's timezone
          if(gatewayParam[activeSiteData?.siteID]?.payLoad)setDrivers(gatewayParam[activeSiteData?.siteID].payLoad)
        }
      },[activeSiteData,gatewayParam])

      const getLoggedData = async (getData)=>{      
        const rootUrl = window.location.origin; 
        try {
          setFetchingData(true)
          const response = await fetch(`${rootUrl}/api/gateways/data/${JSON.stringify(getData)}`, {
            method: 'GET',       
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.token}`
            }
          });
      
          if (!response.ok) {
            const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
            console.error(errorMessage);
            setError(errorMessage);
            setFetchingData(false)
            return;
          }
          setFetchingData(false)
          const jsonData = await response.json()
          const holdData = ammendChartData(jsonData,getData)
          setChartData(holdData)
          return holdData
        } catch (error) {
          setFetchingData(false)
          console.error('An error occurred:', error);
          setError('An error occurred while communicating with the server');
        }
      };

    function ammendChartData(dataFromServer,dataInfo){
      let returnData = {...dataFromServer}
      const gatewayCount =  dataFromServer.gateways.length
      for(let i = 0;i<gatewayCount;i++){
        const paramCount = dataInfo.data[dataFromServer.gateways[i]].length
        const beginTime = dataFromServer.data[dataFromServer.gateways[i]].time[0]
        const endTime = dataFromServer.data[dataFromServer.gateways[i]].time.slice(-1)[0]
        const fullTimeArray = createFullTimeArray(beginTime,endTime)
        for(let j = 0;j<paramCount;j++){
          returnData.data[dataFromServer.gateways[i]][dataInfo.data[dataFromServer.gateways[i]][j][0]][dataInfo.data[dataFromServer.gateways[i]][j][1]] = addNullToData(fullTimeArray,dataFromServer.data[dataFromServer.gateways[i]][dataInfo.data[dataFromServer.gateways[i]][j][0]][dataInfo.data[dataFromServer.gateways[i]][j][1]],dataFromServer.data[dataFromServer.gateways[i]].time)
        }
        dataFromServer.data[dataFromServer.gateways[i]].time = fullTimeArray
      }
      return returnData
    }

    function addNullToData(timeArray,objectArray,originalTime){
      const timeSlotCount = timeArray.length
      let returnObj = {}
      let originCounter = 0
      let min = []
      let max = []
      let average = []
      let _error = true
      for(let i = 0; i<timeSlotCount;i++){
        
        if(timeArray[i]===originalTime[originCounter]){
          if(dataTypeSelect.min)min[i] = objectArray?.min[originCounter]
          if(dataTypeSelect.max)max[i] = objectArray?.max[originCounter]
          if(dataTypeSelect.avg)average[i] = objectArray?.average[originCounter]
          originCounter++
        }else{
          if(_error){
            _error=false
          }
          min[i] = null
          max[i] = null
          average[i] = null
        }
      }

      if(dataTypeSelect.max)returnObj.max = max
      if(dataTypeSelect.min)returnObj.min = min
      if(dataTypeSelect.avg)returnObj.average = average
      return returnObj
    }

    function createFullTimeArray(beginTime,endTime){
      const _factor = 60*ANALYTICS_RES
      const loopCount = (endTime-beginTime)/_factor 
      let timeArray = []
      
      for(let i = 0;i<loopCount;i++){
        timeArray[i] = (beginTime+_factor*i)
      }
      return timeArray 
    }

    const getDataInfoFromServer = useCallback(async ()=>{
      const rootUrl = window.location.origin; 
      if(gatewayDataInfo!==null)return // ensure that the request only occures once when loaded
      const listOfArrays = activeSiteData?.gateways.map((item)=>(item.gatewayID))
      try {
        const fetchDataJSON = {gateway_id: listOfArrays,siteID:activeSiteData.siteID}
        const response = await fetch(`${rootUrl}/api/gateways/logInfo/${JSON.stringify(fetchDataJSON)}`, {
          method: 'GET',       
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`
          }
        });
    
        if (!response.ok) {
          const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
          console.error(errorMessage);
          setError(errorMessage);
          return;
        }
        const holdResJson =  await response.json();
        setGatewayDataInfo(holdResJson)
        return holdResJson
      } catch (error) {
        console.error('An error occurred:', error);
        setError('An error occurred while communicating with the server');
      }
    },[gatewayDataInfo, activeSiteData, user.token])

      
      useEffect(()=>{
        getDataInfoFromServer()
      },[drivers,getDataInfoFromServer])

    const handelPlotList = (gatewayID,deviceName, parameterName) => {
      if(chartData)setChartData(null)
      setLiStyles((prevStyles) => {
        const currentStyle = prevStyles[`${gatewayID}.${deviceName}.${parameterName}`];
        const newStyle = {};

        if (!currentStyle || currentStyle.color !== 'green') {
          if(selectedCount<FETCH_LIM)newStyle[`${gatewayID}.${deviceName}.${parameterName}`] = { color: 'green',cursor:'pointer',userSelect: 'none', paddingLeft: '1.1rem' };
        }else{
          newStyle[`${gatewayID}.${deviceName}.${parameterName}`] = { color: '#384975',cursor:'pointer',userSelect: 'none', paddingLeft: '1rem'};
        }

        return {
          ...prevStyles,
          ...newStyle,
        };
      });
    }; 
    
    const handelOnclick_li=(event,value,index,indexPage,deviceIndex,paramIndex)=>{
      let thisArray = {...listToFetchData}
      if(thisArray!==undefined)(!thisArray[value])?(thisArray[value]=(selectedCount<FETCH_LIM)?true:false):(thisArray[value]=false)
      setListToFetchData(thisArray)
    }
    const timeFromSubmit_from = (e, gatewayID,clear) => {
      const gatewayCount = activeSiteData.gateways.length
      let holdArray = { ...dataFetchTime };
      holdArray.from = holdArray.from || {};
      for(let i = 0;i<gatewayCount;i++){
        holdArray.from[activeSiteData.gateways[i].gatewayID] = (new Date(e.target.value).getTime());
      }
      setDataFetchTime(holdArray);
    };
    
    const timeFromSubmit_to = (e, gatewayID) => {
     
      const gatewayCount = activeSiteData.gateways.length
      let holdArray = { ...dataFetchTime };
      holdArray.to = holdArray.to || {};
      for(let i = 0;i<gatewayCount;i++){
        holdArray.to[activeSiteData.gateways[i].gatewayID] = (new Date(e.target.value).getTime());
      }
      
      setDataFetchTime(holdArray);
    };
    

    const onClickFetchData=()=>{
      if(listToFetchData===null){alert("Please select parameters");return}
      const getInfoForChartPlot=(carryArray)=>{
        const listLength =carryArray.length
        let chartInfo = {}
        for(let i = 0;i<listLength;i++){
          if(carryArray[i][1]===true){
            const carrySplitArray = carryArray[i][0].split('.')
            if(chartInfo[carrySplitArray[0]]===undefined)chartInfo[carrySplitArray[0]] = []
            chartInfo[carrySplitArray[0]].push([carrySplitArray[1],carrySplitArray[2],carrySplitArray[3]])
          }
        }
        setChartArrayList(chartInfo)
      }
      let arrayList = Object.entries(listToFetchData)
      getInfoForChartPlot(arrayList)
      const arrayList_length = arrayList.length
      let listOfSelectedGateways = []
      let holdDataRequestPayload = {}
      for(let i = 0;i<arrayList_length;i++){
        if(arrayList[i][1]===true){
          const holdArrayList = arrayList[i][0].split('.')
          if(holdDataRequestPayload[holdArrayList[0]] === undefined){
            listOfSelectedGateways.push(holdArrayList[0])
            holdDataRequestPayload[holdArrayList[0]] = []
            holdDataRequestPayload[holdArrayList[0]].push([holdArrayList[1],holdArrayList[2]])
          }else{
            holdDataRequestPayload[holdArrayList[0]].push([holdArrayList[1],holdArrayList[2]])
          }
        }
      }
      if(listOfSelectedGateways.length>0){
        // call function to GET data
        if(dataFetchTime===null){
          alert("Please complete the time selection")
          return
        }else if(dataFetchTime.from===undefined||dataFetchTime.to===undefined){
          alert("Please complete the time selection")
          return
        }

        for(let i =0;i<listOfSelectedGateways.length;i++){ 
          if(dataFetchTime.from[listOfSelectedGateways[i]]===undefined||dataFetchTime.from[listOfSelectedGateways[i]]===undefined){
            alert("Please complete the time selection")
            return
          }
        }
        getLoggedData({gateways:listOfSelectedGateways,data:holdDataRequestPayload,time:dataFetchTime,siteID:activeSiteData.siteID,res:ANALYTICS_RES,max:dataTypeSelect.max,min:dataTypeSelect.min,avg:dataTypeSelect.avg})
      }
    }

    const closeForm = () => {
      dispatch({ type: 'SET_FORM_FLAG', payload: null });
      dispatch({ type: 'SET_FORMOPEN_FLAG', payload: null });
    };

    const openForm = () => {
      dispatch({ type: 'SET_FORM_FLAG', payload: true });
    };
    function getSiteName(siteID){
      const siteCount = userSite.length
      for(let i = 0;i<siteCount;i++){
        if(siteID===userSite[i].site_id){return userSite[i].siteName}
      }
      return null
    }

    function downloadDataToCSV(){
      let siteName = getSiteName(activeSiteData.siteID)
      if(siteName===null)siteName=''
      const gatewayCount = chartData.gateways.length
      for(let i = 0;i<gatewayCount;i++){
        const gatewayID  =  chartData.gateways[i]
        const argument = {data:chartData.data[gatewayID],arrayList:chartArrayList[gatewayID],gateway:gatewayID}
        let csvString =''
      {
        const fileName = `${argument.gateway}`;
        const numberOfDevices = argument.arrayList.length
        const numberOfDataPoints =argument.data.time.length
        let csvArray_hold = [[]]
        const maxString = dataTypeSelect.max?`${argument.arrayList[0][0]}.${argument.arrayList[0][1]}.max,`:''
        const minString = dataTypeSelect.min?`${argument.arrayList[0][0]}.${argument.arrayList[0][1]}.min,`:''
        csvString = `data:text/csv;charset=utf-8,Date_Time,${maxString}${minString}${argument.arrayList[0][0]}.${argument.arrayList[0][1]}.average,`
        for(let i =1;i<numberOfDevices;i++){
          const _maxString = dataTypeSelect.max?`${argument.arrayList[i][0]}.${argument.arrayList[i][1]}.max,`:''
          const _minString = dataTypeSelect.min?`${argument.arrayList[i][0]}.${argument.arrayList[i][1]}.min,`:''
          csvString += (`${_maxString}${_minString}${argument.arrayList[i][0]}.${argument.arrayList[i][1]}.average,`)        
        }
        // done with the deader
        csvString += '\n'
        for (let i = 0; i < numberOfDataPoints; i++) {
          let holdRow = (function () { 
            let returnValue = [];
            returnValue[0] = new Date((argument.data.time[i]+timeZoneErr*60-timeOffset)*1000).toISOString(); // assigning the time timeZoneErr
            for (let j = 0; j < numberOfDevices; j++) {
              if(dataTypeSelect.max)returnValue.push(argument.data[argument.arrayList[j][0]][argument.arrayList[j][1]].max[i]);
              if(dataTypeSelect.min)returnValue.push(argument.data[argument.arrayList[j][0]][argument.arrayList[j][1]].min[i]);
              if(dataTypeSelect.avg)returnValue.push(argument.data[argument.arrayList[j][0]][argument.arrayList[j][1]].average[i]);
            }
            return returnValue;
          })(); // Call the anonymous function immediately
        
          csvString += holdRow.join(',') + '\n'
        }
      }
        const link = document.createElement("a");
        link.setAttribute("href", encodeURI(csvString));
        link.setAttribute("download", `${siteName} (${gatewayID} ${argument.data.time[0]} - ${argument.data.time.slice(-1)[0]}).csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);     
    
      }
    }

    const deviceNameRes =(deviceListName)=>{
      let holdDeviceList = {...deviceList}
      if(holdDeviceList?.[deviceListName]){
        holdDeviceList[deviceListName] = false
      }else {
        holdDeviceList[deviceListName] = true
      }
      setDeviceList(holdDeviceList)
    }

    const modSelectedList = (_info)=>{
      const holdArray = _info.split('.')
      return `${holdArray[1]} : ${holdArray[2]}`
    }

    const handelSelectedList = (_info)=>{
      const holdArray = _info.split('.')
      handelPlotList(holdArray[0],holdArray[1],holdArray[2])
      handelOnclick_li(null,_info)
    }

    const clearSelection = () => {
      setLiStyles({});
      setListToFetchData(null);
      setChartData(null);
    };

    useEffect(()=>{
      if(gatewayDataInfo&&activeSiteData){
        if(!(dataFetchTime?.from?.[activeSiteData.gateways[0].gatewayID]||dataFetchTime?.to?.[activeSiteData.gateways[0].gatewayID])){
          const timeValue_max = new Date(addTimeOffset(gatewayDataInfo?.dataTime[activeSiteData.gateways[0].gatewayID]?.theLatest)).getTime()
          const gatewayCount = activeSiteData.gateways.length
          let holdArray = { ...dataFetchTime };
          holdArray.from = holdArray.from || {};
          holdArray.to = holdArray.to || {};
          for(let i = 0;i<gatewayCount;i++){
            holdArray.from[activeSiteData.gateways[i].gatewayID] = timeValue_max - 86400000
            holdArray.to[activeSiteData.gateways[i].gatewayID] = timeValue_max
          }
          setDataFetchTime(holdArray)
        }
      }
    },[gatewayDataInfo,activeSiteData])


    function addTimeOffset(date){
      if(date)return new Date(new Date(date).getTime()-timeOffset*1000).toISOString()
      return new Date(0).toISOString()
    }

    const timeSelec = [['24h',1,'Past 24h'],['7d',7,'Past 7 days'],['14d',14,'Past 14 days'],['mon',31,'Past Month'],['3mon',92,'Past 3 Months'],['6mon',185,'Past 6 Month'],['y',366,'Past Year']];

    function quickDate(e,index){
      
      setqdSelect(index)
      const _browserTime = Date.now()   
            
      if(timeSelec[index]?.[1]){
        let newDates = {from:{},to:{}}
        activeSiteData?.gateways?.map((item,i)=>{newDates.from[item?.gatewayID] = (_browserTime-timeSelec[index][1]*86400000); newDates.to[item?.gatewayID] = _browserTime;})
        setDataFetchTime(newDates);
      }
      
    }

    const TimeSelectionDataFetch = ()=>{
      return(
        <div style={{backgroundColor:'#eaeaea',height:'100%'}}>
          <div className='dataExTime'>
            {timeSelec.map((item,index)=>{return (<button id = {`${item[0]}`} onClick={(e)=>quickDate(e.target,index)} className={`defaultButton ${qdSelect===index?'select':''}`}>{item[2]}</button>)})}
          </div>
          
          {false&&<>
            <div>
              <h1>{`Selected Parameters ${selectedCount}/${FETCH_LIM}`}</h1>
            </div>
            <div className='sitePage-timeContainer'>
              {(activeSiteData!==null)?
                <div>
                  {<div>From<input value={new Date(dataFetchTime?.from?.[activeSiteData.gateways[0].gatewayID]?dataFetchTime.from[activeSiteData.gateways[0].gatewayID]:0).toISOString().slice(0, -8)} onChange= {(e)=>timeFromSubmit_from(e,activeSiteData.gateways[0].gatewayID)} min={addTimeOffset(gatewayDataInfo?.dataTime[activeSiteData.gateways[0].gatewayID]?.theFirst).split('.')[0]} max={addTimeOffset(gatewayDataInfo?.dataTime[activeSiteData.gateways[0].gatewayID]?.theLatest)} type="datetime-local" id="dateTimeFrom" /></div>}
                  {<div>To<input value={new Date(dataFetchTime?.to?.[activeSiteData.gateways[0].gatewayID]?dataFetchTime.to[activeSiteData.gateways[0].gatewayID]:0).toISOString().slice(0, -8)} onChange= {(e)=>timeFromSubmit_to(e,activeSiteData.gateways[0].gatewayID)} min={addTimeOffset(gatewayDataInfo?.dataTime[activeSiteData.gateways[0].gatewayID]?.theFirst).split('.')[0]} max={addTimeOffset(gatewayDataInfo?.dataTime[activeSiteData.gateways[0].gatewayID]?.theLatest)} type="datetime-local" id="dateTimeTo" /></div>}
                          
                </div>
              :<></>}
            </div>
          </>}
          <br></br>
          <hr/>
          <br></br>
          <div className='paramList'>
          <h1>Selected Parameters {`${selectedCount}/${FETCH_LIM}`}</h1>
            <ul>
              {(listToFetchData !== null&&listToFetchData !== undefined) ? Object.entries(listToFetchData).map((item,index) => (item[1]?(<li  key = {index}>{(item[1])?modSelectedList(item[0]):null}<button className = 'defaultButton' style={{marginLeft: 'auto',marginRight: '1rem'}} onClick = {()=>handelSelectedList(item[0])}>X</button></li>):(!index&&selectParamMessage))) : selectParamMessage}
            </ul>
          </div>
            <div>
              <div className="checkbox-container">
                <div>
                  <label className = 'checkbox-label'>Average</label>
                  <input checked={true} disabled = {true} onChange={(event)=>{setDataTypeSelect({...dataTypeSelect,avg:event.target.checked});setChartData(null);}} className = 'checkbox' type="checkbox"></input>
                </div>

                <div>
                  <label className = 'checkbox-label'>Maximum</label>
                  <input checked = {dataTypeSelect.max} onChange={(event)=>{setDataTypeSelect({...dataTypeSelect,max:event.target.checked});setChartData(null);}}  className = 'checkbox' type="checkbox"></input>
                </div>
                

                <div>
                  <label className = 'checkbox-label'>Minimum</label>
                  <input checked = {dataTypeSelect.min} onChange={(event)=>{setDataTypeSelect({...dataTypeSelect,min:event.target.checked});setChartData(null);}}  className = 'checkbox' type="checkbox"></input>
                </div>
              </div>
              <div>
                <button disabled = {fetchingData?true:false} className = 'defaultButton' onClick={onClickFetchData}>Fetch Data</button>
                <button disabled = {chartData?false:true} className = 'defaultButton' onClick={openForm}>Show Chart Data</button>
                <button disabled = {chartData?false:true} className = 'defaultButton' onClick={downloadDataToCSV}>Download Data</button>
                <button className = 'defaultButton' onClick={()=>clearSelection()}>Clear Selection</button>
              </div>
            </div>
          </div>
      )
    }

    return (
    <div style = {{position:'relative', width:'100vw'}}>
      <div className='sitePage-flex'>    
        <div className='sitePage-container'>
          {drivers !== null ? (
            activeSiteData.gateways.map((gateway, index) => (
              <div key={index}>
                <>{userRole==='admin'&&<h1>{gateway.gatewayID}</h1>}</>
                {drivers[gateway?.gatewayID].map((page, indexPage) => (
                  <ul key={indexPage}>
                    {page !== null ? (
                      page.map((device, deviceIndex) => (
                        <li key={deviceIndex}>
                          <div className='dashboard-button' key={`${device.deviceName}_${deviceIndex}`} onClick={()=>deviceNameRes(`${device.deviceName}_${deviceIndex}`)} style = {{paddingLeft:'2rem',cursor:'pointer',userSelect: 'none'}}>{device.deviceName}</div>
                          {true?
                          <div className={`paramList ${deviceList?.[`${device.deviceName}_${deviceIndex}`]?"paramList-open":"paramList-closed"}`}>
                            <ul>
                              {device.parameters.map((param, paramIndex) => (
                                <li
                                  key={paramIndex}
                                  value = {`${gateway.gatewayID}.${device.deviceName}.${param.parameterName}.${param.unit}.${indexPage}.${param.deviceValue}`}
                                  style={(liStyles[`${gateway.gatewayID}.${device.deviceName}.${param.parameterName}`]||{paddingLeft: '1rem'})}
                                  onClick={(event) => {
                                    handelPlotList(gateway.gatewayID,device.deviceName, param.parameterName);
                                    /*setLastClickedLi(`${gateway.gatewayID}.${device.deviceName}.${param.parameterName}`);*/
                                    handelOnclick_li(event,`${gateway.gatewayID}.${device.deviceName}.${param.parameterName}.${param.unit}.${indexPage}.${param.deviceValue}`,index,indexPage,deviceIndex,paramIndex);
                                  }}
                                >
                                  {param.parameterName}
                                </li>
                              ))}
                            </ul>
                          </div>
                          :<></>}
                        </li>
                      ))
                    ) : null}
                  </ul>
                ))}
              </div>
            ))
          ) : (
            <ComponentLoading/>
          )}
        </div>
        {isLanscape?<TimeSelectionDataFetch/>:<></>}
        </div>
          {!isLanscape?<div className={`overLay ${overlay?"overLay-true":"overLay-false"}`}>
            <div className='overlayTab'><div onClick={()=>overlay?setOverlay(false):setOverlay(true)}>
              <img className={`svg1 svg1-active ${overlay?'':'left-arrow'}`} src={Right}></img>
            </div>
        </div>
            <div style = {{background: '#00000000',width:'90vw',paddingRight:'0.5vw'}}><TimeSelectionDataFetch/></div>
          </div>:<></>}

          {(formFlag==true)&&<div className="form-popup-bg is-visible">
            <button className="defaultButton" onClick={closeForm} style={{position: 'absolute', top: '0px', right: '0.3rem'}}>Esc</button>     
          <div className={`chartContainer${isLanscape?"":" chartContainer-vertical"}`}> 
            {(chartData!==null&&chartData!==undefined&&chartArrayList!==null)?chartData.gateways.map(item => (<DataChart argument={{data:chartData.data[item],gateway:item,arrayList:chartArrayList[item], timeZoneErr,clean:isLanscape,selected:dataTypeSelect}}/>)):(null)}
            
        </div>
      </div>}
    </div>
  );
};

export default SitePageDeviceList;